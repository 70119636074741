<template>
  <PermissionGroupEditView disabled />
</template>

<script>

import PermissionGroupEditView from '@/views/permissionGroup/PermissionGroupEditView'

export default {
  name: 'PermissionGroupView',
  components: {
    PermissionGroupEditView
  }
}
</script>
