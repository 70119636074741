<script>

import PermissionGroupNewView from '@/views/permissionGroup/PermissionGroupNewView'

export default {
  name: 'PermissionGroupEditView',
  extends: PermissionGroupNewView,
  data () {
    return {
      groupLoaded: false
    }
  },
  methods: {
    getGroup () {
      this.$store.dispatch('group/fetchOne', this.$route.params.id)
        .then(() => {
          this.group = this.$store.getters['group/detail']
          this.groupLoaded = true
        })
    }
  },
  created () {
    this.getGroup()
  }
}
</script>
